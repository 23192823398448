import { registerApplication, start } from 'single-spa';
import { constructApplications, constructRoutes, constructLayoutEngine } from 'single-spa-layout';
import mfeLayoutNa from './mfe-layout-na.html';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';

import './global-nav.css';

const LAYOUTS_BY_REGION = {
  NA: mfeLayoutNa,
};

const NAVIGATION_APPS = ['@reibus/topnav'];
const NO_NAV_PATHS = [
  '/login',
  '/logout',
  '/verification',
  '/set-password',
  '/forgot-password',
  '/forgotPassword',
];

function initAwsRum() {
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: process.env.RUM_GUEST_ROLE_ARN,
      identityPoolId: process.env.RUM_COGNITO_IDENTITY_POOL,
      endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
      telemetries: ['http', 'errors', 'performance'],
      allowCookies: true,
      enableXRay: true,
    };

    const APPLICATION_ID: string = process.env.RUM_APPLICATION_ID;
    const APPLICATION_VERSION: string = '1.0.0';
    const APPLICATION_REGION: string = 'us-east-1';

    const awsRum: AwsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
}

async function init() {
  const layout = LAYOUTS_BY_REGION[process.env.REGION];
  const routes = constructRoutes(layout);

  const applications = constructApplications({
    routes,
    loadApp: ({ name }) => {
      if (process.env.ENVIRONMENT === 'development') {
        console.debug(`Loading app ${name}`);
      }
      return System.import(name);
    },
  });

  applications.forEach(application => {
    if (NAVIGATION_APPS.includes(application.name)) {
      application.activeWhen = location => {
        const found = NO_NAV_PATHS.some(path => location.pathname.startsWith(path));
        return !found;
      };
    }
  });

  const layoutEngine = constructLayoutEngine({ routes, applications, active: false });

  applications.forEach(registerApplication);
  const module = await System.import('@reibus/frontend-utility');
  await module.ready;

  layoutEngine.activate();
  initAwsRum();
  start();
}

init();
